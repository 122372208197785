<template>
	<div class="article-page">
		<div class="search-module mb20">
			<a-input-search v-model="searchInfo" placeholder="标题/时间" enter-button="搜索" size="large" :loading="searchIng"  @search="onSearch" />
		</div>
		<div class="control-box flex flex-end mb20">
			<a-button type="primary" @click="toAdd" style="margin-right: 10px;">
				新增
			 </a-button>
		<!-- 	 <a-button type="primary" @click="toAdd">
			 	批量导出
			  </a-button> -->
		</div>
		<div class="table-box">
			<a-table
				:rowKey="(item,index) => {return index}"
				:loading="dataLoading"
				:columns="columns"
				:data-source="list"
				:pagination="{hideOnSinglePage: true, current: page.current, total: page.total}"
				:bordered="true">
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="toEdit(item)">
							编辑
						</a-button>
						<a-popconfirm
						    title="确认删除该文章"
						    ok-text="是"
						    cancel-text="否"
						    @confirm="deleteArticle(item)"
						  >
							<a-button size="small" type="danger">
								删除
							 </a-button>
						  </a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
	let columns = [{
		title: 'ID',
		key: 'id',
		dataIndex:'id'
	},{
		title: '分类',
		key: 'cat_name',
		dataIndex:'cat_name'
	},{
		title: '标题',
		key: 'title',
		dataIndex:'title'
	},{
		title: '排序',
		key: 'sort',
		dataIndex:'sort'
	},{
		title: '创建时间',
		key: 'create_at_str',
		dataIndex:'create_at_str'
	},{
		title: '操作',
		dataIndex: 'operation',
		width: '128px',
		scopedSlots: { customRender: 'operation' }
	}]
	export default {
		data() {
			return {
				searchInfo: '',
				searchIng: false,
				dataLoading: false,
				columns:columns,
				list:[],
				page:{
					current: 1,
					total: 0
				}
			}
		},
		computed: {
			
		},
		created() {
			
		},
		mounted() {
			
			this.getData()
		},
		methods:{
			getData() {
				let t = this
				t.dataLoading = true
				t.$get({
					port: 'a',
					url: 'airticleList',
					data: {
						type: 1,
						page: t.page.current,
						searchInfo: t.searchInfo
					}
				}).then(res => {
					t.dataLoading = false
					t.searchIng = false
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.dataLoading = false
					t.searchIng = false
				})
			},
			toEdit(item) {
				this.$router.push({path: '/study/addArticle', query:{airticle_id: item.airticle_id}})
			},
			toAdd() {
				this.$router.push({path:'/study/addArticle'})
			},
			onSearch() {
				let t  = this
				t.page.current = 1
				t.searchIng = true
				t.getData()
			},
			deleteArticle(item) {
				let t = this
				t.$get({
					port: 'a',
					url: 'airticleDel',
					data: {
						airticle_id: item.airticle_id
					}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success(msg, 1.5)
						t.getData()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			}
		}
	}
</script>

<style lang="less">
</style>
